import { useEffect } from 'react';
import SMSlogo from '../../../assets/images/imessage-logo.png';
import './index.scss';
import { Flex } from '@opsdti-global-component-library/amgen-design-system';

interface SmsTemplateProps {
	templateContent: string;
}

const SmsTemplate = ({ templateContent }: SmsTemplateProps) => {
	useEffect(() => {
		if (templateContent) {
			const parser = new DOMParser();
			const doc = parser.parseFromString(templateContent || '', 'text/html');

			const element = document.getElementById(
				'subscriptions-sms-example-content'
			);
			if (element) {
				element.innerHTML = doc.querySelector('body')?.innerHTML || '';
			}
		}
	}, [templateContent]);

	return (
		<Flex vertical className="subscriptions-sms-template">
			<Flex
				align="center"
				justify="center"
				className="subscriptions-sms-top-circle"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="9"
					height="9"
					viewBox="0 0 9 9"
					fill="none"
				>
					<circle cx="4.5" cy="4.5" r="4.5" fill="#F2F2F2" />
				</svg>
			</Flex>
			<Flex vertical className="subscriptions-sms1">
				<Flex
					align="flex-end"
					justify="space-between"
					className="subscriptions-sms2"
				>
					<Flex align="flex-end">
						<img src={SMSlogo} />
						<p className="message">MESSAGE</p>
					</Flex>
					<p className="now">NOW</p>
				</Flex>
				<div className="subscriptions-sms3">
					<div
						id="subscriptions-sms-example-content"
						className="subscriptions-sms-example-content"
					></div>
				</div>
			</Flex>
		</Flex>
	);
};

export default SmsTemplate;
