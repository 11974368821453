// Notifications table's features related types
export type FilterSetting = {
	workstream?: string;
	arche_type?: string;
};

export type SelectedSubscriber = {
	name: string;
	delegate_user_id: string;
	is_delegatee: boolean;
};

// Notifications table's schema related types

/**
 * This enum is used to standardize the column names of the notifications table.
 */
export enum TableColumns {
	NAME = 'NAME',
	PAGE = 'PAGE',
	CHANNEL = 'CHANNEL',
	ARCHE_TYPE = 'ARCHE_TYPE',
	SUBSCRIBER = 'SUBSCRIBER',
	DETAIL = 'DETAIL',
	ACTION = 'ACTION',
}

/**
 * This enum is used to map the column names to some specific API response keys.
 */
export enum ColumnArcheMap {
	NAME = 'name',
	PAGE = 'workstream',
	CHANNEL = 'channel_id',
	ARCHE_TYPE = 'arche_type',
	SUBSCRIBER = 'SUBSCRIBER',
	DETAIL = 'metric_details',
	ACTION = 'is_subscribed',
}

// Other types

/**
 * This type is to standardize the actions that can be performed on a notification.
 */

export enum SubscriptionAction {
	ADD_SUBSCRIBER = 'ADD_SUBSCRIBER',
	UPDATE_SUBSCRIBER = 'UPDATE_SUBSCRIBER',
	DELETE_SUBSCRIBER = 'DELETE_SUBSCRIBER',
	APPROVE_SUBSCRIBER = 'APPROVE_SUBSCRIBER',
	DENY_SUBSCRIBER = 'DENY_SUBSCRIBER',
}

export interface DelegateResponse {
	delegate_name: string;
	delegate_id: string;
}

export interface DelegateObject {
	name: string;
	user_id: string;
	is_delegatee: boolean;
}

export type DelegateList = Array<DelegateObject>;

export enum UserRole {
	NONE = 'NONE',
	USER = 'USER',	
	APPROVER = 'APPROVER',
	ADMIN = 'ADMIN',
}

export enum AllowedParams {
	AVAILABLE = 'available',
	MANAGE_USERS = 'manage-users',
	APPROVAL_REQUESTS = 'approval-requests',
}

export enum UserAdGroupToggle {
	USER = 'User',
	ADGROUP = 'AD Group',
}

export enum TabKeys {
	SUBSCRIBED_TAB_KEY = 'notifications-subscribed-tab',
	AVAILABLE_TAB_KEY = 'notifications-available-tab',
	MANAGE_TAB_KEY = 'notifications-manage-tab',
	APPROVAL_TAB_KEY = 'notifications-approval-tab',
}

export enum ApprovalModalSettings {
	DEFAULT = 'default',
	SECONDARY = 'secondary',
}