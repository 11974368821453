import { Loader } from '@gitlab-rtsensing/component-library';
import {
	Flex,
	Search,
	Segmented,
} from '@opsdti-global-component-library/amgen-design-system';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHandleSubscription } from '../../../hooks/use-handle-subscription';
import { getTableEntries } from '../../../services/get-table-entries';
import { AppContext } from '../../../state/app-context';
import {
	FetchNotificationResponse,
	SubscriptionAction,
	UserAdGroupToggle,
	UserType,
} from '../../../types';
import ManageUsersModal from '../../modals/manage-users-modal';
import PreviewModal from '../../modals/preview-modal';
import ValidationModal from '../../modals/validation-modal';
import ManageUsersTable from '../../tables/manage-users-table';
import '../index.scss';

const ManageUsersTabContent = () => {
	const { setTableContentLoader, userFullname } = useContext(AppContext);
	const [userData, setUserData] = useState<any>([]);
	const [manageUserData, setManageUserData] = useState({
		active_subscriptions: '',
		full_name: '',
		userid: '',
	});
	const [selectedTab, setSelectedTab] = useState<string | number>(
		UserAdGroupToggle.USER
	);
	const [userAction, setUserAction] = useState<
		'edit' | 'unsubscribe' | undefined
	>(undefined);
	const [openManageUserModal, setOpenManageUserModal] =
		useState<boolean>(false);
	const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
	const [openValidationModal, setOpenValidationModal] =
		useState<boolean>(false);
	const [searchInput, setSearchInput] = useState<string>('');
	const [notificationData, setNotificationData] = useState<
		FetchNotificationResponse | undefined
	>();
	const [openUpdateLoader, setOpenUpdateLoader] = useState(false);
	const [manageLoader, setManageLoader] = useState(true);

	const updateUserModalState = useCallback((state: boolean) => {
		setOpenManageUserModal(state);
	}, []);

	const userModalDismissed = useCallback(() => {
		setOpenManageUserModal(false);
	}, []);

	const previewModalDismissed = useCallback(() => {
		setOpenPreviewModal(false);
	}, []);

	const unsubscribeModalDismissed = useCallback(() => {
		setOpenValidationModal(false);
	}, []);

	const updateModalDismissed = useCallback(() => {
		setOpenUpdateLoader(false);
	}, []);

	// Button click handler for EDIT and UNSUBSCRIBE buttons in the Manage Users Modal
	const handleButtonClick = (action: 'edit' | 'unsubscribe', selectedRow) => {
		userModalDismissed();
		setNotificationData(selectedRow);
		if (action === 'edit') {
			setUserAction('edit');
			setOpenPreviewModal(true);
		} else if (action === 'unsubscribe') {
			setUserAction('unsubscribe');
			setOpenValidationModal(true);
		}
	};

	const handleChange = (event) => {
		setSearchInput(event?.target?.value);
	};

	// function to filter the Manage Users table based on the user's search input
	const SearchData = (entries: any, searchType: string | number) => {
		const filteredData = entries.filter((entry) => {
			if (searchInput && searchInput.trim()) {
				if (searchType === UserAdGroupToggle.USER) {
					return (
						entry?.full_name
							.toLowerCase()
							.includes(searchInput.toLowerCase()) ||
						(String(entry?.userid) + '@amgen.com')
							.toLowerCase()
							.includes(searchInput.toLowerCase())
					);
				} else {
					return entry?.full_name
						.toLowerCase()
						.includes(searchInput.toLowerCase());
				}
			} else {
				return entries;
			}
		});
		return filteredData;
	};
	const filteredData = userData.length > 0 && SearchData(userData, selectedTab);

	//
	const mutation = useHandleSubscription('ManageList');
	const handleSubscription = (
		action: SubscriptionAction,
		notification: FetchNotificationResponse
	) => {
		// Before API call initialize lazy loading in the Table component.
		// tableContentLoader is a boolean value which if true shows loader in Table component.
		setTableContentLoader(true);
		previewModalDismissed();
		if (action === SubscriptionAction.UPDATE_SUBSCRIBER) {
			setOpenUpdateLoader(true);
		}

		const updatedData = { ...notification };
		mutation.mutate({
			data: {
				is_admin: true,
				user_full_name: userFullname,
				notifications: [
					{
						notification_id: updatedData.id,
						user_frequency: updatedData.frequency,
						delivery_channel: updatedData.selected_channels ?? [],
						is_enabled: updatedData.is_enabled ?? false,
						action_type: action,
					},
				],
				is_delegatee: true,
				delegate_user_id: manageUserData.userid,
				delegate_type:
					selectedTab === UserAdGroupToggle.ADGROUP
						? UserType.AD
						: UserType.USER,
			},
			notification: updatedData,
		});
	};

	// hit the get_table_entries to get either the users or AD groups lists and their active subscriptions.
	// API is hit whenever the USER/AD GROUP toggle is switched.
	// Lazy loading is implemented.
	useEffect(() => {
		let tableEntriesPolling: NodeJS.Timeout;

		const fetchTableEntries = async () => {
			getTableEntries(selectedTab === UserAdGroupToggle.USER ? true : false)
				.then((response) => {
					const data = response?.data?.data;
					setUserData(data);
				})
				.catch((error) => console.log(error))
				.finally(() => setManageLoader(false));

			tableEntriesPolling = setInterval(() => {
				getTableEntries(selectedTab === UserAdGroupToggle.USER ? true : false)
					.then((response) => {
						const data = response?.data?.data;
						setUserData(data);
					})
					.catch((error) => console.log(error));
			}, 10000);
		};

		fetchTableEntries();

		return () => clearInterval(tableEntriesPolling);
	}, [selectedTab]);

	useEffect(() => {
		const appRoot = document && document?.getElementsByTagName('html')[0];
		if (!openManageUserModal) {
			appRoot.style.overflowY = '';
		}
	}, [openManageUserModal]);

	return (
		<>
			<div className="notifications-tab-content">
				<Flex className="notifications-table-controls">
					<Flex className="notifications-table-left-controls">
						<Segmented
							onChange={(value: string | number) => {
								setSelectedTab(value);
								setSearchInput('');
								setManageLoader(true);
							}}
							options={[UserAdGroupToggle.USER, UserAdGroupToggle.ADGROUP]}
							disabled={manageLoader}
						/>
					</Flex>
					<Flex className="notifications-table-right-controls">
						<Search
							onChange={handleChange}
							onSearch={() => {}}
							placeholder={
								!searchInput.trim()
									? `Search ${
											selectedTab === UserAdGroupToggle.USER ? 'by' : 'for'
									  } name${
											selectedTab === UserAdGroupToggle.USER ? ' or e-mail' : ''
									  }`
									: undefined
							}
							value={searchInput}
							disabled={manageLoader}
							style={{ width: '282px' }}
						/>
					</Flex>
				</Flex>
				{manageLoader ? (
					<Flex
						justify="center"
						align="center"
						flex={1}
						className="subscriptions-loader"
					>
						<Loader />
					</Flex>
				) : (
					<ManageUsersTable
						tableClassName="notifications-table"
						data={filteredData}
						selectedTab={selectedTab}
						updateUserModalState={updateUserModalState}
						setManageUserData={setManageUserData}
					/>
				)}
			</div>

			{openManageUserModal && (
				<ManageUsersModal
					isOpen={openManageUserModal}
					closeModal={() => {
						updateUserModalState(false);
						setOpenManageUserModal(false);
					}}
					selectedTab={selectedTab}
					data={manageUserData}
					handleButtonClick={handleButtonClick}
				/>
			)}
			<PreviewModal
				open={openPreviewModal}
				previewModalDismissed={previewModalDismissed}
				handleSubscription={handleSubscription}
				notification={notificationData}
				setOpenUpdateWarning={setOpenValidationModal}
				setOpenManageUserModal={setOpenManageUserModal}
				manageUserName={manageUserData.full_name}
			/>
			<ValidationModal
				open={openValidationModal}
				status="WARNING"
				header={`Unsubscribe "${manageUserData?.full_name
					.split(', ')
					.reverse()
					.join(' ')}" from\n${notificationData?.workstream} - ${
					notificationData?.name
				}?`}
				content="Unsubscribing will remove the notification from ''Subscribed Notifications'' and you will have to subscribe again from the ''Available Notificiations'' tab."
				modalDismissed={unsubscribeModalDismissed}
				handleSubscription={handleSubscription}
				notification={notificationData}
				setOpenPreview={
					userAction && userAction === 'edit'
						? setOpenPreviewModal
						: setOpenManageUserModal
				}
			/>
			<ValidationModal
				open={openUpdateLoader}
				status="UPDATE"
				header=""
				content=""
				modalDismissed={updateModalDismissed}
				handleSubscription={handleSubscription}
				notification={notificationData}
			/>
		</>
	);
};

export default ManageUsersTabContent;
