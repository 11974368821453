import { Loader, Modal } from '@gitlab-rtsensing/component-library';
import {
	Button,
	Flex,
	Paragraph,
	StatusIcon,
	Title,
	theme,
} from '@opsdti-global-component-library/amgen-design-system';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../../state/app-context';
import { FetchNotificationResponse, SubscriptionAction } from '../../../types';
import './index.scss';
interface ValidationModalProps {
	status: 'WARNING' | 'SUCCESS' | 'UPDATE';
	header: string;
	content: string;
	open: boolean;
	modalDismissed: () => void;
	handleSubscription?: (
		action: SubscriptionAction,
		notification: FetchNotificationResponse
	) => void;
	notification?: FetchNotificationResponse | undefined;
	request?: any | undefined;
	setOpenPreview?: (open: boolean) => void;
}

const ValidationModal = ({
	status,
	header,
	content,
	open,
	modalDismissed,
	handleSubscription,
	notification,
	request,
	setOpenPreview,
}: ValidationModalProps) => {
	const { tableContentLoader } = useContext(AppContext);

	const { token } = theme;

	useEffect(() => {
		if (open) {
			if (!tableContentLoader && status === 'UPDATE') {
				modalDismissed();
			}
		}
	}, [modalDismissed, status, tableContentLoader, open]);

	return !notification && !request ? null : (
		<Modal
			opened={open}
			onClose={() => {
				if (!tableContentLoader) {
					modalDismissed();
				}
			}}
			className="subscriptions-validation-modal"
		>
			<Modal.Header contentClassName="subscriptions-validation-modal-header">
				{notification ? (
					<Modal.Title
						title={
							notification.is_subscribed
								? status !== 'UPDATE'
									? 'Unsubscribe'
									: `Sensing ${notification.workstream} Subscription - Edit`
								: `Sensing ${notification.workstream} Subscription - Subscribe`
						}
					/>
				) : (
					<Modal.Title title={`Sensing ${request.page} Subscriptions`} />
				)}
			</Modal.Header>
			{tableContentLoader && status !== 'WARNING' ? (
				<Flex
					justify="center"
					align="center"
					flex={1}
					className="subscriptions-loader"
				>
					<Loader />
				</Flex>
			) : status !== 'UPDATE' ? (
				<div
					className={`subscriptions-validation-modal-content ${
						status === 'WARNING' ? 'warning-modal' : 'success-modal'
					}`}
				>
					<Flex
						justify="center"
						align="center"
						className="subscriptions-validation-modal-rectangle"
					>
						{status === 'WARNING' ? (
							<StatusIcon status="warning" height={46} width={46} />
						) : (
							<StatusIcon status="success" height={46} width={46} />
						)}
					</Flex>
					<Flex
						vertical
						justify="center"
						className="subscriptions-validation-modal-body"
					>
						<Title level={3} className="subscriptions-validation-modal-header">
							{header}
						</Title>
						<Paragraph
							className="subscriptions-validation-modal-body-content"
							style={{ color: token?.colorTextLabel }}
						>
							{content}
						</Paragraph>
					</Flex>
					<Flex
						align="center"
						justify="flex-end"
						className="subscriptions-validation-modal-footer"
					>
						{status === 'WARNING' &&
						notification &&
						handleSubscription &&
						setOpenPreview ? (
							<>
								<Button
									className=""
									text="CANCEL"
									onClick={() => {
										modalDismissed();
										setOpenPreview(true);
									}}
									type="link"
								/>
								<Button
									className="subscriptions-validation-modal-unsubscribe-button"
									text="UNSUBSCRIBE"
									onClick={() => {
										handleSubscription(SubscriptionAction.DELETE_SUBSCRIBER, {
											...notification,
											is_subscribed: false,
										});
										modalDismissed();
									}}
									type="primary"
								/>
							</>
						) : (
							<>
								<Button
									className=""
									text={notification ? 'BACK TO SUBSCRIPTION MANAGER' : 'CLOSE'}
									onClick={() => {
										modalDismissed();
									}}
									type="primary"
								/>
							</>
						)}
					</Flex>
				</div>
			) : (
				<></>
			)}
		</Modal>
	);
};

export default ValidationModal;
