import {
	Label,
	Table,
	Text,
	theme,
	Flex,
} from '@opsdti-global-component-library/amgen-design-system';
import sortData from '../../../../utils/sort-data';
import '../index.scss';

interface AdUsersTableProps {
	adUsers: any;
}

const AdUsersTable = ({ adUsers }: AdUsersTableProps) => {
	const { token } = theme;

	return (
		<Flex vertical gap={8} style={{ paddingBottom: '20px' }}>
			<Label
				style={{
					color: token?.colorText,
					marginTop: '4px',
					marginLeft: '1px',
				}}
			>
				{`${adUsers.length} users`}
			</Label>
			<Table
				key="manage-ad-users-table"
				bordered={false}
				columns={[
					{
						dataIndex: 'fullname',
						key: 'user_name',
						sortDirections: ['descend', 'ascend', 'descend'],
						defaultSortOrder: 'ascend',
						title: (
							<Text strong className="subscriptions-table-column">
								USER NAME
							</Text>
						),
						sorter: (a, b, sortOrder) =>
							sortData(a?.fullname, b?.fullname, sortOrder),
						width: '142px',
						ellipsis: true,
					},
					{
						dataIndex: 'loginname',
						key: 'user_id',
						title: (
							<Text strong className="subscriptions-table-column">
								USER ID
							</Text>
						),
						width: '86px',
						ellipsis: true,
					},
					{
						dataIndex: 'position',
						key: 'position',
						title: (
							<Text strong className="subscriptions-table-column">
								POSITION
							</Text>
						),
						width: '190px',
						ellipsis: true,
					},
				]}
				dataSource={adUsers}
				scroll={{
					x: '300px',
					y: adUsers.length > 8 ? '285px' : undefined,
				}}
				size="small"
				style={{
					width: '434px',
				}}
			/>
		</Flex>
	);
};

export default AdUsersTable;
