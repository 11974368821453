import { Loader } from '@gitlab-rtsensing/component-library';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHandleSubscription } from '../../../hooks/use-handle-subscription';
import { AppContext } from '../../../state/app-context';
import {
	DelegateList,
	DelegateObject,
	FetchNotificationResponse,
	FilterSetting,
	SubscriptionAction,
	SubscriptionType,
	UserType,
} from '../../../types';
import { getUniqueValues } from '../../../utils/get-unique-values';
import FilterComponent from '../../filter-component';
import PreviewModal from '../../modals/preview-modal';
import ValidationModal from '../../modals/validation-modal';
import NoSubscriptions from '../../no-subscription';
import NotificationsTable from '../../tables/notifications-table';
import '../index.scss';
import { Flex } from '@opsdti-global-component-library/amgen-design-system';

interface ManageSubscriptionsTabContentProps {
	data: Array<FetchNotificationResponse>;
	length?: number;
	type: SubscriptionType;
	filter: FilterSetting;
	delegates: DelegateList;
	tableLoader: boolean;
	setFilter: (setting: FilterSetting) => void;
}

const ManageSubscriptionsTabContent = ({
	data,
	type,
	filter,
	delegates,
	tableLoader,
	setFilter,
}: ManageSubscriptionsTabContentProps) => {
	const {
		userFullname,
		selectedSubscriber,
		isRefetchingNotifications,
		tableContentLoader,
		setTableContentLoader,
		setOriginalList,
		setEnablePolling,
		setSelectedSubscriber,
	} = useContext(AppContext);

	const [notificationData, setNotificationData] = useState<
		FetchNotificationResponse | undefined
	>();
	const [openPreview, setOpenPreview] = useState(false);
	const [openUpdateWarning, setOpenUpdateWarning] = useState(false);
	const [openSubscribeSuccess, setOpenSubscribeSuccess] = useState(false);
	const [openUpdateLoader, setOpenUpdateLoader] = useState(false);
	const [uniquePages, setUniquePages] = useState<
		Array<{ key: string; label: string }>
	>([]);
	const [uniqueTriggers, setUniqueTriggers] = useState<
		Array<{ key: string; label: string }>
	>([]);
	const [filterPivot, setFilterPivot] = useState<string | undefined>(undefined);

	const previewModalDismissed = useCallback(() => {
		setOpenPreview(false);
	}, []);

	const unsubscribeModalDismissed = useCallback(() => {
		setOpenUpdateWarning(false);
	}, []);

	const subscribeModalDismissed = useCallback(() => {
		setOpenSubscribeSuccess(false);
	}, []);

	const updateModalDismissed = useCallback(() => {
		setOpenUpdateLoader(false);
	}, []);

	const mutation = useHandleSubscription(
		type === SubscriptionType.SUBSCRIBED
			? 'SubscriptionList'
			: 'NotificationList'
	);
	const handleSubscription = (
		action: SubscriptionAction,
		notification: FetchNotificationResponse
	) => {
		setTableContentLoader(true);
		previewModalDismissed();
		if (action === SubscriptionAction.ADD_SUBSCRIBER) {
			setOpenSubscribeSuccess(true);
		} else if (action === SubscriptionAction.UPDATE_SUBSCRIBER) {
			setOpenUpdateLoader(true);
		}

		const updatedData = { ...notification };
		mutation.mutate({
			data: {
				is_admin: false,
				user_full_name: userFullname,
				notifications: [
					{
						notification_id: updatedData.id,
						user_frequency: updatedData.frequency,
						delivery_channel: updatedData.selected_channels ?? [],
						is_enabled: updatedData.is_enabled ?? false,
						action_type: action,
					},
				],
				is_delegatee: selectedSubscriber.is_delegatee,
				delegate_user_id:
					updatedData.is_ad_group && updatedData.ad_group
						? updatedData.ad_group
						: selectedSubscriber.delegate_user_id,
				delegate_type: updatedData.is_ad_group ? UserType.AD : UserType.USER,
			},
			notification: updatedData,
		});
	};

	const updatePageFilter = useCallback(() => {
		const uniquePagesCopy: Array<{ key: string; label: string }> =
			getUniqueValues(data, 'workstream')
				.sort()
				.map((page) => {
					return { key: page, label: page };
				});
		if (uniquePagesCopy.length > 1) {
			uniquePagesCopy.unshift({ key: 'All', label: 'All' });
		}
		setUniquePages([...uniquePagesCopy]);
	}, [data, setUniquePages]);

	const updateTriggerFilter = useCallback(() => {
		const uniqueTriggersCopy: Array<{ key: string; label: string }> =
			getUniqueValues(data, 'arche_type')
				.sort()
				.map((trigger) => {
					return { key: trigger, label: trigger };
				});
		if (uniqueTriggersCopy.length > 1) {
			uniqueTriggersCopy.unshift({ key: 'All', label: 'All' });
		}
		setUniqueTriggers([...uniqueTriggersCopy]);
	}, [data, setUniqueTriggers]);

	const handleSubscriberSelect = (event: string) => {
		if (event && delegates) {
			const subscriber = delegates.filter((sub: DelegateObject) => {
				return sub.name === event;
			});
			setSelectedSubscriber({
				name: subscriber[0].name,
				delegate_user_id: subscriber[0].user_id,
				is_delegatee: subscriber[0].is_delegatee,
			});
		}
		setFilter({});
		setUniquePages([]);
		setUniqueTriggers([]);
	};

	const handleFilterSelection = useCallback(
		(attribute: string, event: string) => {
			let updatedFilter = { ...filter };
			if (event !== 'All') {
				if (filterPivot === undefined) {
					setFilterPivot(attribute);
					updatedFilter = {};
				}

				if (attribute === 'PAGE') {
					updatedFilter.workstream = event;
					if (filterPivot === attribute) {
						delete updatedFilter.arche_type;
					}
				} else if (attribute === 'TRIGGER') {
					updatedFilter.arche_type = event;
					if (filterPivot === attribute) {
						delete updatedFilter.workstream;
					}
				}
			} else {
				if (attribute === 'PAGE') {
					delete updatedFilter.workstream;
					if (filterPivot === attribute) {
						delete updatedFilter.arche_type;
					}
				} else if (attribute === 'TRIGGER') {
					delete updatedFilter.arche_type;
					if (filterPivot === attribute) {
						delete updatedFilter.workstream;
					}
				}
			}

			setFilter({ ...updatedFilter });
			setTableContentLoader(true);
		},
		[filter, filterPivot, setFilter, setTableContentLoader]
	);

	useEffect(() => {
		if (!filter.workstream) {
			updatePageFilter();
		}
		if (!filter.arche_type) {
			updateTriggerFilter();
		}

		if (isRefetchingNotifications && !tableContentLoader) {
			updatePageFilter();
			updateTriggerFilter();
		}
	}, [
		filter,
		isRefetchingNotifications,
		tableContentLoader,
		updatePageFilter,
		updateTriggerFilter,
	]);

	useEffect(() => {
		// in every refetch we want to set the original list to the new data
		// and check if there are any pending notifications.
		// No polling required if there's no data.
		if (data.length) {
			setOriginalList(data);
			// check if there are remaining pending notifications
			const isPending = data.some((notification) => notification.is_processing);
			// if false disable polling
			setEnablePolling(isPending);
		}
	}, [data, setEnablePolling, setOriginalList]);

	const delegatesList: Array<{ key: string; label: string }> =
		delegates?.map((subscriber: any) => {
			return {
				label:
					subscriber.name ===
					`${userFullname.split(', ')[1]} ${userFullname.split(', ')[0]}`
						? `${subscriber.name} (You)`
						: subscriber.name,
				key: subscriber.name,
			};
		}) ?? [];

	return (
		<>
			<div className="notifications-tab-content">
				<Flex className="notifications-table-controls">
					<Flex className="notifications-table-left-controls">
						<FilterComponent
							type={'SUBSCRIBER'}
							header={'Managing for'}
							optionsList={delegatesList}
							tableLoader={tableLoader}
							handleSubscriberSelect={handleSubscriberSelect}
						/>
					</Flex>
					<Flex className="notifications-table-right-controls">
						<FilterComponent
							header={'PAGE'}
							optionsList={uniquePages}
							filter={filter}
							tableLoader={tableLoader}
							handleFilterSelection={handleFilterSelection}
						/>
						<FilterComponent
							header={'TRIGGER'}
							optionsList={uniqueTriggers}
							filter={filter}
							tableLoader={tableLoader}
							handleFilterSelection={handleFilterSelection}
						/>
					</Flex>
				</Flex>
				{tableLoader || (tableContentLoader && !data.length) ? (
					<Flex
						justify="center"
						align="center"
						flex={1}
						className="subscriptions-loader"
					>
						<Loader />
					</Flex>
				) : !data.length && type === SubscriptionType.SUBSCRIBED ? (
					<NoSubscriptions />
				) : (
					<NotificationsTable
						notificationsList={data}
						type={type}
						tableClassName="notifications-table"
						setNotificationData={setNotificationData}
						setOpen={setOpenPreview}
					/>
				)}
			</div>

			<PreviewModal
				open={openPreview}
				previewModalDismissed={previewModalDismissed}
				handleSubscription={handleSubscription}
				notification={notificationData}
				setOpenUpdateWarning={setOpenUpdateWarning}
			/>
			<ValidationModal
				open={openUpdateWarning}
				status="WARNING"
				header={`Unsubscribe "${selectedSubscriber.name}" from\n${notificationData?.workstream} - ${notificationData?.name}?`}
				content="Unsubscribing will remove the notification from ''Subscribed Notifications'' and you will have to subscribe again from the ''Available Notificiations'' tab."
				modalDismissed={unsubscribeModalDismissed}
				handleSubscription={handleSubscription}
				notification={notificationData}
				setOpenPreview={setOpenPreview}
			/>
			<ValidationModal
				open={openSubscribeSuccess}
				status="SUCCESS"
				header="Your subscription is now pending."
				content="Subscriptions can take up to two minutes to become active. View your active notifications under the ''Subscribed Notifications'' tab."
				modalDismissed={subscribeModalDismissed}
				handleSubscription={handleSubscription}
				notification={notificationData}
			/>
			<ValidationModal
				open={openUpdateLoader}
				status="UPDATE"
				header=""
				content=""
				modalDismissed={updateModalDismissed}
				handleSubscription={handleSubscription}
				notification={notificationData}
			/>
		</>
	);
};

export default ManageSubscriptionsTabContent;
