import {
	Button,
	Label,
	Table,
	Text,
	theme,
	Flex,
} from '@opsdti-global-component-library/amgen-design-system';
import { FetchNotificationResponse } from '../../../../types';
import sortData from '../../../../utils/sort-data';

interface UserTableProps {
	activeSubscriptions: any;
	handleButtonClick: (
		action: 'edit' | 'unsubscribe',
		selectedRow: FetchNotificationResponse | undefined
	) => void;
}

const ActiveSubscriptionsTable = ({
	activeSubscriptions,
	handleButtonClick,
}: UserTableProps) => {
	const { token } = theme;
	const getActionButtons = (selectedRow: FetchNotificationResponse) => {
		return selectedRow.is_processing ? (
			<Button
				text="PENDING"
				style={{ pointerEvents: 'none' }}
				type="link"
				disabled
			/>
		) : (
			<Flex style={{ marginLeft: '-8px' }}>
				<Button
					text="EDIT"
					onClick={() => {
						handleButtonClick('edit', selectedRow);
					}}
					type="link"
				/>
				<Button
					text="UNSUBSCRIBE"
					onClick={() => {
						handleButtonClick('unsubscribe', selectedRow);
					}}
					type="link"
				/>
			</Flex>
		);
	};

	return (
		<Flex vertical gap={8} style={{ paddingBottom: '20px' }}>
			<Label
				style={{
					color: token?.colorText,
					marginTop: '4px',
					marginLeft: '1px',
				}}
			>
				{`${activeSubscriptions.length} Subscriptions`}
			</Label>
			<Table
				key="manage-active-subscriptions-table"
				bordered={false}
				columns={[
					{
						dataIndex: 'workstream',
						key: 'workstream',
						sortDirections: ['descend', 'ascend', 'descend'],
						defaultSortOrder: 'ascend',
						title: (
							<Text strong className="subscriptions-table-column">
								PAGE
							</Text>
						),
						sorter: (a, b, sortOrder) =>
							sortData(a?.workstream, b?.workstream, sortOrder),
						width: '76px',
						ellipsis: true,
					},
					{
						dataIndex: 'name',
						key: 'name',
						sortDirections: ['descend', 'ascend', 'descend'],
						title: (
							<Text strong className="subscriptions-table-column">
								NAME
							</Text>
						),
						sorter: (a, b, sortOrder) => sortData(a?.name, b?.name, sortOrder),
						width: '190px',
						ellipsis: true,
					},
					{
						dataIndex: 'action',
						key: 'action',
						title: (
							<Text strong className="subscriptions-table-column">
								ACTION
							</Text>
						),
						render: (_, notification) =>
							getActionButtons(notification as FetchNotificationResponse),
						width: '143px',
						fixed: 'right',
					},
				]}
				dataSource={activeSubscriptions}
				scroll={{
					x: '300px',
					y: activeSubscriptions.length > 8 ? '285px' : undefined,
				}}
				size="small"
				style={{
					width: '434px',
				}}
			/>
		</Flex>
	);
};

export default ActiveSubscriptionsTable;
