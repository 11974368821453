import {
	Select,
	Text,
	theme,
	Flex,
} from '@opsdti-global-component-library/amgen-design-system';
import { FetchNotificationResponse } from '../../../../types';

interface PreviewModalFreqProps {
	notification: FetchNotificationResponse;
	setSelectedFrequency: (value: string) => void;
}

const PreviewModalFreq = ({
	notification,
	setSelectedFrequency,
}: PreviewModalFreqProps) => {
	const isADGoup = notification?.is_ad_group;

	const { token } = theme;

	return (
		<>
			<Text strong className="subscriptions-preview-modal-freq">
				Frequency
			</Text>
			<Flex align="center">
				<Text
					className="subscriptions-preview-modal-freq-detail"
					style={{ color: token?.colorTextLabel }}
				>
					You receive notifications of updates
					{isADGoup ? (
						<div className="bold-text">
							&nbsp;{`${notification?.frequency}.`}
						</div>
					) : (
						<>
							:
							<Select
								className="subscriptions-preview-modal-select"
								onChange={(value) => {
									setSelectedFrequency(value as string);
								}}
								options={[
									{
										label:
											notification?.frequency[0].toUpperCase() +
											notification?.frequency.slice(1),
										value: notification?.frequency,
									},
								]}
								defaultValue={notification?.frequency}
							/>
						</>
					)}
				</Text>
			</Flex>
		</>
	);
};

export default PreviewModalFreq;
