import SubscriptionsRoutes from '../components/subscriptions-routes';
import { QueryClient, QueryClientProvider } from 'react-query';

const Container: React.FC = () => {
	const queryClient = new QueryClient();
	return (
		<>
			<QueryClientProvider client={queryClient}>
				<SubscriptionsRoutes />
			</QueryClientProvider>
		</>
	);
};

export default Container;
