import { LoginCallback } from '@okta/okta-react';
import { ThemeProvider } from '@opsdti-global-component-library/amgen-design-system';
import { useContext, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Subscriptions from '../../pages';
import { AppContext } from '../../state/app-context';
import {
	AdmissibleUserRoles,
	FetchNotificationResponse,
	SelectedSubscriber,
} from '../../types';
import AuthComponent from '../auth-component';
import RequiredAuthComponent from '../auth-component/required-auth';
import PageLayout from '../page-layout';

const SubscriptionsRoutes: React.FC = () => {
	const { userFullname, userId } = useContext(AppContext);
	const [isRefetchingNotifications, setIsRefetchingNotifications] =
		useState<boolean>(false);
	const [tableContentLoader, setTableContentLoader] = useState<boolean>(false);
	const [selectedSubscriber, setSelectedSubscriber] =
		useState<SelectedSubscriber>({
			name: `${userFullname.split(', ')[1]} ${userFullname.split(', ')[0]}`,
			is_delegatee: false,
			delegate_user_id: '',
		});
	const [queryDataKey, setQueryDataKey] = useState<
		'NotificationList' | 'SubscriptionList' | undefined
	>(undefined);
	const [originalList, setOriginalList] = useState<FetchNotificationResponse[]>(
		[]
	);
	const [enablePolling, setEnablePolling] = useState<boolean>(false);
	const [firstLoadNotificationsTab, setFirstLoadNotificationsTab] =
		useState<boolean>(true);
	const [userRole, setUserRole] = useState<AdmissibleUserRoles>(undefined);

	const themeService = null;

	return (
		<AuthComponent>
			<ThemeProvider themeService={themeService}>
				<PageLayout>
					<AppContext.Provider
						value={{
							userFullname: userFullname,
							userId: userId,
							isRefetchingNotifications: isRefetchingNotifications,
							setIsRefetchingNotifications,
							tableContentLoader: tableContentLoader,
							setTableContentLoader: setTableContentLoader,
							selectedSubscriber: selectedSubscriber,
							setSelectedSubscriber,
							queryDataKey: queryDataKey,
							setQueryDataKey: setQueryDataKey,
							originalList: originalList,
							setOriginalList,
							enablePolling: enablePolling,
							setEnablePolling,
							firstLoadNotificationsTab: firstLoadNotificationsTab,
							setFirstLoadNotificationsTab: setFirstLoadNotificationsTab,
							userRole: userRole,
							setUserRole,
						}}
					>
						<Routes>
							<Route path="/" element={<RequiredAuthComponent />}>
								<Route
									path=""
									element={<Navigate to="/subscriptions" />}
								></Route>
							</Route>
							<Route path="/subscriptions" element={<RequiredAuthComponent />}>
								<Route path="" element={<Subscriptions />}></Route>
							</Route>
							<Route
								path="/subscriptions/:subscriptionsPage"
								element={<RequiredAuthComponent />}
							>
								<Route path="" element={<Subscriptions />}></Route>
							</Route>
							<Route path="/implicit/callback" element={<LoginCallback />} />
						</Routes>
					</AppContext.Provider>
				</PageLayout>
			</ThemeProvider>
		</AuthComponent>
	);
};
export default SubscriptionsRoutes;
