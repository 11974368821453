import {
	Button,
	Title,
	Paragraph,
	theme,
	Flex,
} from '@opsdti-global-component-library/amgen-design-system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss';

const NoSubscriptions: React.FC = () => {
	const navigate = useNavigate();

	const { token } = theme;
	return (
		<Flex vertical gap={16} className="no-subscriptions-container">
			<Title level={2}>No Subscriptions</Title>
			<Paragraph
				className="no-subscriptions-text"
				style={{ color: token?.colorTextLabel }}
			>
				{`You have not made any subscriptions. Select the "Available
				Notifications" tab to subscribe to your notifications.`}
			</Paragraph>
			<Button
				text="SEE AVAILABLE NOTIFICATIONS"
				className="no-subscriptions-button"
				onClick={() => {
					navigate('/subscriptions/available');
				}}
				type="primary"
			/>
		</Flex>
	);
};

export default NoSubscriptions;
