import { Loader } from '@gitlab-rtsensing/component-library';
import {
	Flex,
	Tabs,
	Title,
} from '@opsdti-global-component-library/amgen-design-system';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { allowedParams } from '../constants';
import { getDelegates } from '../services/get-delegates';
import { getTabAuth } from '../services/get-tab-auth';
import { AppContext } from '../state/app-context';
import {
	AllowedParams,
	DelegateList,
	DelegateResponse,
	TabKeys,
	TabsAuthResponse,
	UserRole,
} from '../types';
// import ApprovalRequests from './approval-requests';
import './index.scss';
import { Available, MySubscriptions } from './manage-subscriptions';
import ManageUsers from './manage-users';

const Subscriptions: React.FC = () => {
	const navigate = useNavigate();
	const { subscriptionsPage } = useParams();
	const {
		userFullname,
		firstLoadNotificationsTab,
		userRole,
		userId,
		setUserRole,
	} = useContext(AppContext);

	const [delegates, setDelegates] = useState<DelegateList | undefined>(
		undefined
	);
	const [allowedTabs, setAllowedTabs] = useState<
		| undefined
		| { children: React.ReactNode; key: string; label: React.ReactNode }[]
	>(undefined);
	const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
	const [isAllowedParam, setIsAllowedParam] = useState<boolean>(false);

	useEffect(() => {
		if (isFirstLoad) {
			getTabAuth({
				query: `query {\n  sensingAuthorization(username:"${userId}", workstreams:["notifications"],\n    skipWorkstreamLevelAuth: false){\n notifications notificationsAuthorization {\n  managedUsers pendingApproval subscribedNotifications availableNotifications }\n}\n}`,
				variables: null,
			}).then((response) => {
				const tabsAuth: TabsAuthResponse =
					response?.data?.data?.sensingAuthorization
						?.notificationsAuthorization;

				if (tabsAuth.managedUsers && tabsAuth.pendingApproval) {
					setUserRole(UserRole.ADMIN);
				} else if (!tabsAuth.managedUsers && tabsAuth.pendingApproval) {
					setUserRole(UserRole.APPROVER);
				} else if (tabsAuth.managedUsers && !tabsAuth.pendingApproval) {
          setUserRole(UserRole.USER);
        } else {
					setUserRole(UserRole.NONE);
				}
			});
		}
	}, [isFirstLoad, setUserRole, userId]);

	useEffect(() => {
		if (subscriptionsPage && userRole) {
			if (!allowedParams.includes(subscriptionsPage)) {
				setIsAllowedParam(false);
			} else if (subscriptionsPage === AllowedParams.AVAILABLE) {
				setIsAllowedParam(true);
			} else if (subscriptionsPage === AllowedParams.MANAGE_USERS) {
				if (userRole !== UserRole.ADMIN) {
					setIsAllowedParam(false);
				} else {
					setIsAllowedParam(true);
				}
			} else if (subscriptionsPage === AllowedParams.APPROVAL_REQUESTS) {
				if (userRole !== UserRole.APPROVER && userRole !== UserRole.ADMIN) {
					setIsAllowedParam(false);
				} else {
					setIsAllowedParam(true);
				}
			}
		} else if (!subscriptionsPage || subscriptionsPage === '') {
			setIsAllowedParam(true);
		}
	}, [subscriptionsPage, userRole]);

	useEffect(() => {
		if (userRole && isFirstLoad && delegates && isAllowedParam) {
			const defaultTabs = [
				{
					children: <MySubscriptions delegates={delegates} />,
					key: TabKeys.SUBSCRIBED_TAB_KEY,
					label: <span>Subscribed Notifications</span>,
				},
				{
					children: <Available delegates={delegates} />,
					key: TabKeys.AVAILABLE_TAB_KEY,
					label: <span>Available Notifications</span>,
				},
			];

			if (userRole === UserRole.USER) {
        defaultTabs.push({
          children: <ManageUsers />,
          key: TabKeys.MANAGE_TAB_KEY,
          label: <span>Manage Users</span>,
        });
      }

			if (userRole === UserRole.ADMIN) {
				defaultTabs.push(
					{
						children: <ManageUsers />,
						key: TabKeys.MANAGE_TAB_KEY,
						label: <span>Manage Users</span>,
					},
					// {
					// 	children: <ApprovalRequests />,
					// 	key: TabKeys.APPROVAL_TAB_KEY,
					// 	label: (
					// 		<Flex align="center" gap={5} style={{ marginRight: '2px' }}>
					// 			Approval Requests
					// 			<Tabs.Badge count={3} />
					// 		</Flex>
					// 	),
					// }
				);
			} 
			// else if (userRole === UserRole.APPROVER) {
			// 	defaultTabs.push({
			// 		children: <ApprovalRequests />,
			// 		key: TabKeys.APPROVAL_TAB_KEY,
			// 		label: (
			// 			<Flex align="center" gap={5}>
			// 				Approval Requests
			// 				<Tabs.Badge count={3} />
			// 			</Flex>
			// 		),
			// 	});
			// }
			setIsFirstLoad(false);
			setAllowedTabs(defaultTabs);
		}
	}, [
		delegates,
		navigate,
		userRole,
		firstLoadNotificationsTab,
		isFirstLoad,
		isAllowedParam,
	]);

	useEffect(() => {
		if (userFullname && isFirstLoad && isAllowedParam) {
			getDelegates({})
				.then((response) => {
					const subscribersInfo: DelegateList = response.data.data.map(
						(delegate: DelegateResponse) => {
							return {
								name: delegate.delegate_name,
								user_id: delegate.delegate_id,
								is_delegatee: true,
							};
						}
					);
					subscribersInfo.unshift({
						name: `${userFullname.split(', ')[1]} ${
							userFullname.split(', ')[0]
						}`,
						user_id: '',
						is_delegatee: false,
					});
					setDelegates(subscribersInfo);
				})
				.catch((error) => console.log(error));
		}
	}, [userFullname, isFirstLoad, isAllowedParam]);

	const handleTabClick = (tabKey) => {
		if (tabKey === TabKeys.SUBSCRIBED_TAB_KEY) {
			navigate('/subscriptions/');
		} else if (tabKey === TabKeys.AVAILABLE_TAB_KEY) {
			navigate('/subscriptions/available');
		} else if (tabKey === TabKeys.MANAGE_TAB_KEY) {
			navigate('/subscriptions/manage-users');
		} 
		// else {
		// 	navigate('/subscriptions/approval-requests');
		// }
	};

	return !isAllowedParam ? (
		<></>
	) : !allowedTabs ? (
		<Flex
			justify="center"
			align="center"
			flex={1}
			className="subscriptions-loader"
		>
			<Loader />
		</Flex>
	) : (
		<>
			<Flex className="subscriptions-header">
				<Title level={1} style={{ fontWeight: 600 }}>
					Manage Notifications
				</Title>
			</Flex>
			{subscriptionsPage === AllowedParams.AVAILABLE ? (
				<Tabs
					key="subscriptions-available-tab"
					className="notifications-main-tabs"
					items={allowedTabs}
					defaultActiveKey={TabKeys.AVAILABLE_TAB_KEY}
					onTabClick={(event) => {
						handleTabClick(event);
					}}
				/>
			) : userRole === UserRole.ADMIN &&
			  subscriptionsPage === AllowedParams.MANAGE_USERS ? (
				<Tabs
					key="subscriptions-manage-users-tab"
					className="notifications-main-tabs"
					items={allowedTabs}
					defaultActiveKey={TabKeys.MANAGE_TAB_KEY}
					onTabClick={(event) => {
						handleTabClick(event);
					}}
				/>
			) : (userRole === UserRole.APPROVER || userRole === UserRole.ADMIN) &&
			  subscriptionsPage === AllowedParams.APPROVAL_REQUESTS ? (
				// <Tabs
				// 	key="subscriptions-approval-requests-tab"
				// 	className="notifications-main-tabs"
				// 	items={allowedTabs}
				// 	defaultActiveKey={TabKeys.APPROVAL_TAB_KEY}
				// 	onTabClick={(event) => {
				// 		handleTabClick(event);
				// 	}}
				// />
				<></>
			) : (
				<Tabs
					key="subscriptions-my-subscriptions-tab"
					className="notifications-main-tabs"
					items={allowedTabs}
					defaultActiveKey={TabKeys.SUBSCRIBED_TAB_KEY}
					onTabClick={(event) => {
						handleTabClick(event);
					}}
				/>
			)}
		</>
	);
};

export default Subscriptions;
