import { Loader, Modal } from '@gitlab-rtsensing/component-library';
import {
	Flex,
	Tabs,
} from '@opsdti-global-component-library/amgen-design-system';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useUpdateNotificationsList } from '../../../hooks/use-update-notifications-list';
import { fetchAdUsers } from '../../../services/fetch-ad-users';
import { fetchNotificationsList } from '../../../services/fetch-notifications';
import { AppContext } from '../../../state/app-context';
import {
	FetchNotificationResponse,
	SubscriptionType,
	UserAdGroupToggle,
	UserType,
} from '../../../types';
import ActiveSubscriptionsTable from './active-subscriptions-table';
import AdUsersTable from './ad-users-table';
import './index.scss';

type ManageUsersModalProps = {
	isOpen: boolean;
	closeModal: () => void;
	header?: string;
	selectedTab: string | number;
	data: any;
	handleButtonClick: (
		action: 'edit' | 'unsubscribe',
		selectedRow: FetchNotificationResponse | undefined
	) => void;
};

const ManageUsersModal: React.FC<ManageUsersModalProps> = ({
	isOpen,
	closeModal,
	selectedTab,
	data,
	handleButtonClick,
}: ManageUsersModalProps) => {
	const { selectedSubscriber, userRole } = useContext(AppContext);
	const [isFetchComplete, setIsFetchComplete] = useState(false);
	const [adUsers, setAdUsers] = useState<any>(undefined);
	const [enablePolling, setEnablePolling] = useState<boolean>(false);

	const { data: fetchedNotifications, refetch: refetchNotificationsList } =
		useQuery({
			queryKey: ['ManageList'],
			queryFn: fetchNotificationsList.bind(this, {
				data: {
					is_admin: true,
					is_delegatee: true,
					delegate_user_id: data?.userid,
					filters: {},
					subscribed_flag: true,
					delegate_user_type:
						selectedTab === UserAdGroupToggle.USER
							? UserType.USER
							: UserType.AD,
				},
				type: SubscriptionType.SUBSCRIBED,
				getAD: false,
			}),
			enabled: false,
			refetchOnWindowFocus: false,
		});

	const defaultTabs = [
		{
			key: '0',
			label: <span>Subscriptions</span>,
			children: (
				<ActiveSubscriptionsTable
					activeSubscriptions={fetchedNotifications}
					handleButtonClick={handleButtonClick}
				/>
			),
		},
		{
			key: '1',
			label: <span>Users</span>,
			children: <AdUsersTable adUsers={adUsers} />,
		},
	];

	useEffect(() => {
		if (!isFetchComplete) {
			if (selectedTab === UserAdGroupToggle.USER) {
				refetchNotificationsList()
					.catch((e) => console.log(e))
					.finally(() => setIsFetchComplete(true));
			} else {
				Promise.all([
					refetchNotificationsList(),
					fetchAdUsers({
						ad_group: data?.full_name,
					}),
				])
					.then((responses) => {
						const [_, fetchAdUsersResponse] = responses;
						setAdUsers(fetchAdUsersResponse);
					})
					.catch((e) => console.log(e))
					.finally(() => setIsFetchComplete(true));
			}
		}
	}, [
		data,
		isFetchComplete,
		refetchNotificationsList,
		selectedTab,
		userRole,
		selectedSubscriber.delegate_user_id,
		selectedSubscriber.is_delegatee,
	]);

	useEffect(() => {
		// in every refetch we want to set the original list to the new data
		// and check if there are any pending notifications.
		// No polling required if there's no data.
		if (fetchedNotifications && fetchedNotifications.length > 0) {
			// check if there are remaining pending notifications
			const isPending = fetchedNotifications.some(
				(notification) => notification.is_processing
			);
			// if false disable polling
			setEnablePolling(isPending);
		}
	}, [fetchedNotifications]);

	const fetchNotificationsListMutation = useUpdateNotificationsList(
		'ManageList',
		setEnablePolling
	);

	useEffect(() => {
		const notificationListPolling = setInterval(() => {
			if (enablePolling) {
				fetchNotificationsListMutation.mutate({
					data: {
						is_admin: true,
						is_delegatee: true,
						delegate_user_id: data?.userid,
						filters: {},
						subscribed_flag: true,
						delegate_user_type:
							selectedTab === UserAdGroupToggle.USER
								? UserType.USER
								: UserType.AD,
					},
					type: SubscriptionType.SUBSCRIBED,
					getAD: false,
				});
			}
		}, 5000);
		return () => clearInterval(notificationListPolling);
	}, [
		data?.userid,
		enablePolling,
		fetchNotificationsListMutation,
		selectedTab,
	]);

	return (
		<Modal
			opened={isOpen}
			onClose={() => {
				setIsFetchComplete(false);
				closeModal();
			}}
			className="manage-users-modal"
		>
			<Modal.Header contentClassName="subscriptions-validation-modal-header">
				<Modal.Title
					title={`${
						selectedTab === UserAdGroupToggle.USER
							? data.full_name
									.split(', ')
									.sort(() => -1)
									.join(' ')
							: data.full_name
					} - Manage`}
				/>
			</Modal.Header>
			{!isFetchComplete || !fetchedNotifications ? (
				<Flex
					justify="center"
					align="center"
					flex={1}
					className="subscriptions-loader"
				>
					<Loader />
				</Flex>
			) : (
				<div className="manage-modal-content">
					{selectedTab === UserAdGroupToggle.USER ? (
						<ActiveSubscriptionsTable
							activeSubscriptions={fetchedNotifications}
							handleButtonClick={handleButtonClick}
						/>
					) : (
						<Flex vertical>
							<Tabs
								items={defaultTabs}
								style={{ margin: 'unset' }}
								tabBarStyle={{ width: '152px' }}
							/>
						</Flex>
					)}
				</div>
			)}
		</Modal>
	);
};

export default ManageUsersModal;
