import {
	Checkbox,
	Text,
	theme,
	Flex,
} from '@opsdti-global-component-library/amgen-design-system';
import { FetchNotificationResponse } from '../../../../types';

interface PreviewModalChannelProps {
	notification: FetchNotificationResponse;
	checkedChannels: string[];
	subscriptionError: boolean;
	setSubscriptionError: (value: boolean) => void;
	handleChannelCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PreviewModalChannel = ({
	notification,
	checkedChannels,
	subscriptionError,
	setSubscriptionError,
	handleChannelCheck,
}: PreviewModalChannelProps) => {
	const isADGoup = notification?.is_ad_group;

	const { token } = theme;
	return (
		<div style={{ marginTop: '11px' }}>
			<Text strong className="subscriptions-preview-modal-channel">
				Channel
			</Text>
			<Flex align="center">
				<Text
					className="subscriptions-preview-modal-channel-detail"
					style={{ color: token?.colorTextLabel }}
				>
					You receive notifications through
				</Text>
				<Text
					className="subscriptions-preview-modal-channel-detail"
					style={{ color: token?.colorTextLabel }}
				>
					{isADGoup ? (
						<div>
							&nbsp;
							{notification.subscribed_channels.length > 0
								? notification.subscribed_channels
										.map<React.ReactNode>((channel, index) => {
											if (
												channel === 'channel_ms_teams_chat' ||
												channel === 'channel_ms_teams_channel' ||
												channel === 'ms_teams_chat'
											) {
												return <Text key={index}>Teams</Text>;
											} else if (
												channel === 'channel_email' ||
												channel === 'channel_delivery_email'
											) {
												return <Text key={index}>Email</Text>;
											} else {
												return <Text key={index}>SMS</Text>;
											}
										})
										.reduce((prev, curr) => [prev, ' and ', curr])
								: 'N/A'}
							.
						</div>
					) : (
						<Flex>
							:
							{notification?.channel_id.map((channel, index) => {
								return (
									<div
										key={index}
										className="subscriptions-preview-modal-checkbox"
									>
										<Checkbox
											onChange={(e: any) => {
												handleChannelCheck(e);
												if (subscriptionError) {
													setSubscriptionError(false);
												}
											}}
											checked={checkedChannels.includes(channel)}
											value={channel}
										>
											{channel.includes('teams')
												? 'MS Teams'
												: channel.includes('email')
												? 'Email'
												: 'SMS'}
										</Checkbox>
									</div>
								);
							})}
						</Flex>
					)}
				</Text>
			</Flex>
		</div>
	);
};

export default PreviewModalChannel;
