import { Modal } from '@gitlab-rtsensing/component-library';
import {
	Button,
	StatusIcon,
	Tabs,
	Text,
	Title,
	theme,
	Flex,
} from '@opsdti-global-component-library/amgen-design-system';
import { useCallback, useEffect, useState } from 'react';
import { FetchNotificationResponse, SubscriptionAction } from '../../../types';
import EmailTemplate from '../../notification-templates/email-template';
import SmsTemplate from '../../notification-templates/sms-template';
import TeamsTemplate from '../../notification-templates/teams-template';
import './index.scss';
import PreviewModalChannel from './preview-modal-channel';
import PreviewModalFreq from './preview-modal-freq';
import PreviewModalSubheader from './preview-modal-subheader';

interface PreviewModalProps {
	open: boolean;
	previewModalDismissed: () => void;
	handleSubscription: (
		action: SubscriptionAction,
		notification: FetchNotificationResponse
	) => void;
	notification: FetchNotificationResponse | undefined;
	setOpenUpdateWarning: (value: boolean) => void;
	setOpenManageUserModal?: (value: boolean) => void;
	manageUserName?: string;
}

const PreviewModal = ({
	open,
	previewModalDismissed,
	handleSubscription,
	notification,
	setOpenUpdateWarning,
	setOpenManageUserModal,
	manageUserName = '',
}: PreviewModalProps) => {
	const [checkedChannels, setCheckedChannels] = useState<string[]>([]);
	const [selectedFrequency, setSelectedFrequency] = useState('');
	const [subscriptionError, setSubscriptionError] = useState(false);
	const isADGoup = notification?.is_ad_group;

	const { token } = theme;

	const createTemplateTab = (label: string, templateContent: string) => ({
		children:
			label === 'MS Teams' ? (
				<TeamsTemplate templateContent={templateContent} />
			) : label === 'Email' ? (
				<EmailTemplate templateContent={templateContent} />
			) : (
				<SmsTemplate templateContent={templateContent} />
			),
		key: label.toLowerCase(),
		label: <span>{label}</span>,
	});

	const getDefaultTemplateContent = () =>
		'<!DOCTYPE html><html><head></head><body><p><b>No example available</b></p></body>';

	const tabs = notification?.notification_template
		? notification.notification_template.map((template) => {
				const channelName = template.channel_name?.S || template.channel.S;
				if (channelName.includes('teams')) {
					return createTemplateTab(
						'MS Teams',
						template.body?.S || getDefaultTemplateContent()
					);
				} else if (channelName.includes('email')) {
					return createTemplateTab(
						'Email',
						template.body?.S || getDefaultTemplateContent()
					);
				} else {
					return createTemplateTab(
						'SMS',
						template.body?.S || getDefaultTemplateContent()
					);
				}
		  })
		: notification?.channel_id.map((channel) => {
				if (channel.includes('teams')) {
					return createTemplateTab('MS Teams', getDefaultTemplateContent());
				} else if (channel.includes('email')) {
					return createTemplateTab('Email', getDefaultTemplateContent());
				} else {
					return createTemplateTab('SMS', getDefaultTemplateContent());
				}
		  });

	// set of operations to be performed when the modal is opened
	useEffect(() => {
		if (open) {
			if (notification) {
				setSelectedFrequency(notification?.frequency);
			}
			if (notification?.is_subscribed) {
				setCheckedChannels(
					notification.subscribed_channels.length > 0
						? notification.subscribed_channels
						: notification.channel_id
				);
			} else {
				setCheckedChannels([]);
			}
			setSubscriptionError(false);
		}
	}, [open, notification]);

	const handleChannelCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
		const checkedChannel = e.target.value;
		setCheckedChannels((prevCheckedChannels: string[]) => {
			const updatedChannels = prevCheckedChannels.includes(checkedChannel)
				? prevCheckedChannels.filter((channel) => channel !== checkedChannel)
				: [...prevCheckedChannels, checkedChannel];
			return updatedChannels;
		});
	};

	const scrollUserToChannelError = useCallback(() => {
		setTimeout(() => {
			const subscriptionErrorMessage = document.getElementById(
				'subscriptions-preview-modal-error-message'
			);
			if (subscriptionErrorMessage) {
				subscriptionErrorMessage.scrollIntoView({
					behavior: 'smooth',
					block: 'end',
					inline: 'nearest',
				});
			}
		}, 100);
	}, []);

	const handleSubscribeClick = useCallback(
		(notif: FetchNotificationResponse) => {
			if (checkedChannels.length === 0) {
				setSubscriptionError(true);
				scrollUserToChannelError();
			} else {
				setSubscriptionError(false);
				handleSubscription(SubscriptionAction.ADD_SUBSCRIBER, {
					...notif,
					selected_channels: checkedChannels,
					frequency: selectedFrequency,
					is_subscribed: true,
					is_enabled: true,
				});
			}
		},
		[
			checkedChannels,
			handleSubscription,
			scrollUserToChannelError,
			selectedFrequency,
		]
	);

	const handleUpdateClick = useCallback(
		(notif: FetchNotificationResponse) => {
			if (checkedChannels.length === 0) {
				setSubscriptionError(true);
				scrollUserToChannelError();
			} else {
				setSubscriptionError(false);
				handleSubscription(SubscriptionAction.UPDATE_SUBSCRIBER, {
					...notif,
					selected_channels: checkedChannels,
					frequency: selectedFrequency,
				});
			}
		},
		[
			checkedChannels,
			handleSubscription,
			scrollUserToChannelError,
			selectedFrequency,
		]
	);

	return !notification || !tabs ? null : (
		<Modal
			opened={open}
			onClose={() => {
				previewModalDismissed();
			}}
			className="subscriptions-preview-modal"
		>
			<Modal.Header contentClassName="d-flex align-items-center">
				<Modal.Title
					title={`Sensing ${notification.workstream} Subscription - ${
						notification.is_subscribed ? 'Edit' : 'Subscribe'
					}`}
				/>
			</Modal.Header>
			<>
				<div className="subscriptions-preview-modal-content">
					<PreviewModalSubheader manageUserName={manageUserName} />
					<div
						className="subscriptions-notification-example-box"
						style={{
							border: `1px solid ${token?.colorBorderSecondary}`,
							backgroundColor: `${token?.colorBgLayout}`,
						}}
					>
						<Title
							level={3}
							className="subscriptions-notification-example-metric-details"
						>
							{notification?.name}
						</Title>
						<Tabs
							items={tabs}
							style={{ transition: 'none !important', animationDuration: '0s' }}
						/>
					</div>
					<PreviewModalFreq
						notification={notification}
						setSelectedFrequency={setSelectedFrequency}
					/>
					<PreviewModalChannel
						notification={notification}
						checkedChannels={checkedChannels}
						subscriptionError={subscriptionError}
						setSubscriptionError={setSubscriptionError}
						handleChannelCheck={handleChannelCheck}
					/>
					{subscriptionError ? (
						<Flex
							align="center"
							gap={8}
							id="subscriptions-preview-modal-error-message"
							className="subscriptions-preview-modal-error-message"
						>
							<StatusIcon status="error" height={16} width={16} />
							<Text>One channel selection required.</Text>
						</Flex>
					) : null}
					<div style={{ marginTop: '16px' }}>
						<Text strong className="subscriptions-preview-modal-trigger">
							Trigger
						</Text>
						<Flex>
							<Text
								className="subscriptions-preview-modal-trigger-detail"
								style={{ color: token?.colorTextLabel }}
							>
								{notification.arche_type}
							</Text>
						</Flex>
					</div>
				</div>
				<Flex
					align="center"
					justify="flex-end"
					className="subscriptions-preview-modal-footer"
				>
					<Button
						className="subscriptions-preview-modal-cancel-button"
						text="CANCEL"
						onClick={() => {
							previewModalDismissed();
							if (setOpenManageUserModal) {
								setOpenManageUserModal(true);
							}
						}}
						type="link"
					/>
					{!notification.is_subscribed ? (
						<Button
							className="subscriptions-preview-modal-subscribe-button"
							text="SUBSCRIBE TO NOTIFICATION"
							disabled={subscriptionError}
							onClick={() => handleSubscribeClick(notification)}
							type="primary"
						/>
					) : (
						<>
							<Button
								className="subscriptions-edit-modal-button"
								text="UNSUBSCRIBE"
								onClick={() => {
									previewModalDismissed();
									setOpenUpdateWarning(true);
								}}
								type="secondary"
							/>
							{isADGoup ? (
								<></>
							) : (
								<Button
									className="subscriptions-edit-modal-button"
									disabled={subscriptionError}
									text="CONFIRM UPDATE"
									onClick={() => handleUpdateClick(notification)}
									type="primary"
								/>
							)}
						</>
					)}
				</Flex>
			</>
		</Modal>
	);
};

export default PreviewModal;
