import {
	Dropdown,
	Label,
	Text,
	theme,
	Flex,
} from '@opsdti-global-component-library/amgen-design-system';
import { useContext } from 'react';
import { AppContext } from '../../state/app-context';
import { FilterSetting } from '../../types';
import './index.scss';

interface FilterComponentProps {
	type?: string;
	header: string;
	optionsList: Array<{ key: string; label: string }>;
	filter?: FilterSetting;
	tableLoader: boolean;
	handleFilterSelection?: (attribute: string, event: string) => void;
	handleSubscriberSelect?: (event: string) => void;
}

const FilterComponent = ({
	type,
	header,
	optionsList,
	filter,
	tableLoader,
	handleFilterSelection,
	handleSubscriberSelect,
}: FilterComponentProps) => {
	const {
		isRefetchingNotifications,
		tableContentLoader,
		selectedSubscriber,
		setTableContentLoader,
	} = useContext(AppContext);

	const { token } = theme;

	const disableFilter =
		isRefetchingNotifications || tableContentLoader || tableLoader;
	const disableOptions =
		type !== 'SUBSCRIBER' &&
		disableFilter &&
		filter &&
		!Object.keys(filter).length;

	return (
		<Flex align="center" gap={8} className="subscriptions-filter-subscriber">
			{type !== 'SUBSCRIBER' ? (
				<Label>{header}</Label>
			) : (
				<Text
					className="subscription-delegates-filter-header"
					style={{ color: token?.colorTextLabel }}
				>
					{header}
				</Text>
			)}

			{optionsList && optionsList.length > 0 && !disableOptions ? (
				<Dropdown
					className="subscriptions-filter-select"
					onMenuItemClick={(event) => {
						if (type !== 'SUBSCRIBER' && handleFilterSelection) {
							handleFilterSelection(header, event.key);
						} else if (type === 'SUBSCRIBER' && handleSubscriberSelect) {
							handleSubscriberSelect(event.key);
						}
						setTableContentLoader(true);
					}}
					items={optionsList}
					type="button"
					disabled={disableFilter}
					style={{ width: '191px !important' }}
				>
					{type === 'SUBSCRIBER'
						? selectedSubscriber.name === optionsList[0].key
							? optionsList[0].label
							: selectedSubscriber.name
						: filter && !Object.keys(filter).length
						? optionsList[0].label
						: header === 'PAGE'
						? filter?.workstream
							? filter?.workstream
							: optionsList[0].label
						: filter?.arche_type
						? filter?.arche_type
						: optionsList[0].label}
				</Dropdown>
			) : (
				<Dropdown
					className="subscriptions-filter-select"
					items={[{ key: 'group', label: undefined }]}
					disabled
					defaultSelectedKey={undefined}
					onMenuItemClick={() => {}}
					type="button"
				/>
			)}
		</Flex>
	);
};

export default FilterComponent;
