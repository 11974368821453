import ManageUsersTabContent from '../../components/tabs-content/manage-users-tab-content';

const ManageUsers = () => {
	return (
		<div>
			<ManageUsersTabContent />
		</div>
	);
};

export default ManageUsers;
